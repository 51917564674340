import Vue from 'vue';
import VueI18n from 'vue-i18n';
const config = require('./config').default;

Vue.use(VueI18n);

function loadLocaleMessages() {
	const locales = require.context('./assets/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
	const messages = {};
	locales.keys().forEach(key => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i)
		if (matched && matched.length > 1) {
			const locale = matched[1];
			messages[locale] = locales(key);
		}
	});
	return messages
}

export default new VueI18n({
	locale: config.lang,
	fallbackLocale: 'hu',
	messages: loadLocaleMessages(),
	numberFormats: {
		'hu': {
			currency: {
				style: 'currency', currency: 'HUF', currencyDisplay: 'symbol', minimumFractionDigits: 0
			}
		},
		'sk': {
			currency: {
				style: 'currency', currency: 'EUR', currencyDisplay: 'symbol', minimumFractionDigits: 0
			}
		},
		'ro': {
			currency: {
				style: 'currency', currency: 'EUR', currencyDisplay: 'symbol', minimumFractionDigits: 0
			}
		}
	},
	dateTimeFormats: {
		'hu': {
			date: {
				year: 'numeric', month: 'short', day: 'numeric'
			},
			time: {
				hour: 'numeric', minute: 'numeric'
			}
		},
		'sk': {
			date: {
				year: 'numeric', month: 'short', day: 'numeric'
			},
			time: {
				hour: 'numeric', minute: 'numeric'
			}
		},
		'ro': {
			date: {
				year: 'numeric', month: 'short', day: 'numeric'
			},
			time: {
				hour: 'numeric', minute: 'numeric'
			}
		},
	}
})

